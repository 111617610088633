<template>
    <div class="container-fluid">
        <div class="box-header">
            <div class="row">
                <div class="col-md-4">
                    <h4 class="title-in-h">{{$t('user.index.header')}}</h4>
                    <label v-show="info_page.status===true">{{$t('user.index.has')+' '+ info_page.actives+' '+$t('user.index.a_has_plural') }}</label>
                    <label v-show="info_page.status===false">{{$t('user.index.has')+' '+ info_page.inactives+' '+$t('user.index.i_has_plural') }}</label>
                </div>
                <div class="col-md-5 pt-2">
                    <button class="btn   btn-wd   ml-1 mr-1" :class="info_page.status===true?'btn-default':'btn-outline'" @click="change_status(true)">{{$t('form.actives')}}</button>
                    <button class="btn  btn-wd   ml-1 mr-1" :class="info_page.status===false?'btn-default':'btn-outline'" @click="change_status(false)">{{$t('form.inactives')}}</button>
                </div>
            </div>
        </div>

        <breadcrumb>
            <breadcrumb-item>
                <router-link :to="{path:'/admin/properties'}"   class="color-blue-var">{{$t('room.index.home')}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active>
               {{$t('user.index.title')}}
            </breadcrumb-item>
        </breadcrumb>


    <div class="row">
        <vue-topprogress ref="topProgress"></vue-topprogress>

        <div class="col-12">
            <card  v-loading="screenLoading"
                   element-loading-lock="true"
                   element-loading-customClass="min-height-large"
                   :element-loading-text="screenText"
                   element-loading-spinner="el-icon-loading">
                <div>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="mb-3" style="width: 200px">

                            <l-button class="btn-success" @click="createItem('#/admin/users/create')" >{{$t('user.index.new')}}</l-button>
                        </div>
                        <el-input type="search"
                                  class="mb-3"
                                  style="width: 200px"
                                  :placeholder="$t('user.index.search') "
                                  v-model="searchQuery"
                                  aria-controls="datatables"/>
                    </div>
                    <div class="col-sm-12">
                        <el-table
                                  style="width: 100%"
                                  :data="queriedData">
                            <el-table-column min-width="100"  :label="$t('form.name') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container  text-center">
                                        <label>{{getName(row)}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t('form.email') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{row.email}}</label>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column min-width="200"  :label="$t('form.permises') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label v-if="row.is_admin" class="font-weight-bold is_admin">USUARIO ADMINISTRADOR</label>
                                        <div class="box-permissions" v-for="option in row.permissions" v-if="row.permissions.length && row._id!=='' && !row.is_admin">
                                            <label class="title">{{nameProperty(option.property)}}</label>
                                            <label v-for="(item, idx) in option.sections" v-tooltip.bottom="checkPermission(item).textP" :class="checkPermission(item).classP">{{info_page.permissions[idx]}}</label>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column  width="150px" :label="$t('form.actions')">


                                <template slot-scope="{row}">
                                    <div class="text-center">

                                        <l-switch v-model="row.status" type="primary" on-text="Activo" off-text="Inactivo" @input="updateStatus(row._id, row.status)"></l-switch>

                                        <a v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link ml-1"
                                           @click="handleEdit(row._id)"><i
                                                class="fa fa-edit"></i></a>
                                    </div>
                                </template>


                              <!--  <template slot-scope="props">
                                    <div class="text-center">
                                        <l-switch v-model="row.status" type="primary" on-text="Activo" off-text="Inactivo" @input="updateStatus(row._id, row.status)"></l-switch>
                                        <a v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link"
                                           @click="handleEdit(props.$index, props.row.id)"><i
                                                class="fa fa-edit"></i></a>

                                        <a v-tooltip.top-center="$t('form.delete')" class="btn-danger btn-simple btn-link"
                                           @click="handleDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>
                                    </div>
                                </template>-->
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                        <p class="card-category" v-if="total>0">
                            {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                            {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}} </p>
                    </div>
                    <l-pagination class="pagination-no-border"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  :total="pagination.total">
                    </l-pagination>
                </div>
            </card>
        </div>
    </div>

        <div class="box-footer" :class="(records.length)?'showme':''">
            <div class="box-inside text-center">
                <button class="btn btn-success btn-wd  ml-1 mr-1"  @click="saveChanges">Guardar</button>
                <button class="btn btn-danger btn-fill  ml-1 mr-1"  @click="cancelForm">Cancelar</button>
            </div>
        </div>
    </div>

</template>
<script>
    import {Table, TableColumn, Select, Option} from 'element-ui'
    import {Pagination as LPagination, Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    //import users from 'src/pages/Dashboard/Tables/users'
    import swal from 'sweetalert2'
    import Fuse from 'fuse.js'
    import PropertyService from '../../../js/services/PropertyService';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';
    import Helpers from '../../../js/helpers/Helpers';
    // TODO: Revisar que se necesita para eliminar checkpermission
    import Permissions from '../../../js/helpers/PermissionRoles';
    const permission = new Permissions();
    import { vueTopprogress } from 'vue-top-progress';
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    import moment from 'moment';


    import { mapGetters } from "vuex";
    const propertyService = new PropertyService();
    const helpers = new Helpers();
    const logFunctions = new LogFunctions();
    const logService = new LogService();

    export default {
        components: {
            LPagination,
            Breadcrumb,
            BreadcrumbItem,
            LSwitch,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["currentUser", "selected_property","writting_permission"]),
            pagedData() {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            queriedData() {
                let result = this.tableData
                if (this.searchQuery !== '') {
                    result = this.fuseSearch.search(this.searchQuery)
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                this.pagination.total = this.tableData.length
                return this.tableData.length
            }
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                records:[],
                users:[],
                info_page:{
                    original_info:{},
                    section_name: 'user',
                    status: true,
                    actives: 0,
                    inactives: 0,
                    permissions: [],
                    properties: []
                },
                items: [],
                items_i: [],
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name', 'email', 'age'],
                tableColumns: [
                    {
                        prop: 'name',
                        label: this.$t('form.name'),
                        minWidth: 200
                    },
                    {
                        prop: 'email',
                        label: this.$t('form.email'),
                        minWidth: 220
                    },
                    {
                        prop: 'actions',
                        label: this.$t('form.permises'),
                        minWidth: 250
                    }
                ],
                tableData: [],
                fuseSearch: null
            }
        },
        created(){
            this.chargerUsers();
        },
        methods: {
            chargerUsers(){
                this.initLoading();
                this.info_page.permissions = permission.getPermissionsAll();
               //console.log( helpers.getPermissionsAll())

                propertyService.getProperties().then(async response => {
                    this.info_page.properties =  response.data.data.properties;
                })

                propertyService.getPropertyUserAll().then(response => {
                    //console.log(response.data.data.users)

                    let users = response.data.data.users;
                    let users_actives = users.filter(item=>item.status === true);
                    let users_inactives = users.filter(item=>item.status === false);

                    this.items = users_actives;
                    this.info_page.actives = users_actives.length;
                    this.items_i = users_inactives;
                    this.info_page.inactives = users_inactives.length;
                    this.tableData = users_actives;
                    this.closeLoading();

                    this.configLogs(null, this.info_page.section_name);
                });
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 2;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            nameProperty(property_id){

                let item = this.info_page.properties.find(property => property._id === property_id);
                return  (item)?this.text_truncate(item.name, 40):'';
            },
            text_truncate(str, length, ending) {
                if (length == null) {
                    length = 100;
                }
                if (ending == null) {
                    ending = '...';
                }
                if (str.length > length) {
                    return str.substring(0, length - ending.length) + ending;
                } else {
                    return str;
                }
            },
            checkPermission(permission){
              let defaultClass = 'per-none';
              let text = '';
              if(permission===1){

                  defaultClass = 'per-read';
                  text = 'Lectura';
              }

              if(permission===2){
                  defaultClass = 'per-all';
                  text = 'Lectura y escritura';

              }



              return {
                  classP : defaultClass,
                  textP : text
              };
            },

            getName(item){

               return item.first_name + ' '+item.last_name;
            },

            cancelForm(){
                this.chargerUsers();
                this.info_page.status = true;
                this.records = [];
                /*let tableData = this.tableData;
                this.records.forEach(item => {
                    let element = tableData.find(xquery => xquery._id === item._id)
                    element.status = !element.status;
                })
                this.records = [];*/
            },


            updateStatus(id, status){
               //console.log('items modificados', status)
                let records = this.records;
                status = !status;

                let idx = records.findIndex(record => record._id === id);
                if(idx>=0){
                    records.splice(idx, 1);
                }else{
                    records.push({
                        _id: id,
                        status: status
                    })
                }
            },


            saveChanges(){
               //console.log('items modificados', this.records)
                let records = this.records;
                this.initLoading('Guardando usuarios');

                records.forEach(async (item, idx)=>{
                    let status =item.status;
                    let id =item._id;
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }
                        status = 'false';

                    }else{
                        indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }
                        status = 'true';
                    }


                        //propertyService.deleteUser(row.id).then(response => {

                    let user_resp = await propertyService.updateStatusUser(id, status)
                    //console.log('contract response', contract.data.data.rateplan)
                    let user = user_resp.data.data.user;

                    if(user.status===true){
                        await this.items.push(user)
                        let data =  [{
                            parameter : "status",
                            old : false,
                            new : true
                        }];
                        await this.createLog(user._id, 1, data);
                    }else{
                        await this.createLog(user._id, 2, []);
                        await this.items_i.push(user)
                    }
                    this.info_page.actives =  this.items.length;
                    this.info_page.inactives =  this.items_i.length;

                });
                this.records = [];
                setTimeout(()=>{
                    this.closeLoading();
                }, 500)

            },


            change_status(status){
                this.info_page.status=status;
                //console.log(this.info_page.actives, this.info_page.inactives)
                if(status){
                    this.tableData =  this.items;
                }else{
                    this.tableData =  this.items_i;
                }
            },
            trans_status(status){
                let result = 'Inactivo';
                if(status){
                    result = 'Activo';
                }
                return result
            },
            handleEdit(id) {
                this.$router.push({
                    name: 'editUser',
                    params: {
                        id: id
                    }
                })
            },
            handleDelete(index, row) {
                swal({
                    title: this.$t('form.question_delete'),
                    text: this.$t('form.warning_delete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: this.$t('form.yes_deleted'),
                    cancelButtonText: this.$t('form.cancel'),
                    buttonsStyling: false
                }).then((result)=> {
                    if (result.dismiss !== 'cancel') {
                        propertyService.deleteUser(row.id).then(response => {
                            swal({
                                title: this.$t('form.deleted'),
                                text: this.$t('form.been_deleted'),
                                type: 'success',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            })
                            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
                            if (indexToDelete >= 0) {
                                this.tableData.splice(indexToDelete, 1)
                            }
                        });
                    }
                });



            },
            createItem(){

                this.$router.push({
                    name: 'createUser'
                })
            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }

        },
        mounted() {
            this.fuseSearch = new Fuse(this.tableData, {keys: ['name', 'email']})

        }
    }
</script>
<style lang="scss">

    label.is_admin{
        color: #19255A;
    }
    .box-permissions{
        label{
            display: inline-block;
            width: calc(100%/3);
            float: left;
            text-transform: none;
            &.per-none{
                display: none;
            }
            &.per-all{
                color: #5cb85c;

            }
        }

        label.title{
            font-weight: 600;
            text-transform: uppercase;
            width: 100%;
            display: block;
        }
    }
</style>
