import SettingsBase from '../classes/SettingsBase';
import Helper from '../helpers/Helpers';
export default class permissionRoles extends SettingsBase {

  constructor() {
    super();
      this.permissionNames = ['rooms-and-rates', 'reservations', 'marketing', 'revenue', 'contability', 'property']
  }



    getPermissionsAll(){
        return ['Habitaciones y tarifas', 'Reservaciones', 'Marketing', 'Revenue', 'Contabilidad', 'Propiedad']
    }


    getInfoUser(){
        let info_user = JSON.parse(localStorage.getItem('app_settings'));
        let response = info_user.find(item => item.key==='USER').value;
        //console.log('response', response)

        return response;
    }



    checkPermission(permission_name, permission) {
        let  property = JSON.parse(localStorage.getItem('property'));
        let user = this.getInfoUser();

        let is_admin = false;
        if(user){
            is_admin = user.is_admin;
        }
        let permission_r = false;
        if(property !== null && is_admin===false){
            let property_id = property._id;
            let idx = this.permissionNames.findIndex(o => o === permission_name)
            if(user){
                let all_permissions = this.findPermissions(user.permissions, property_id);
                permission_r = all_permissions[idx] >= permission;
            }
        }else if(is_admin && property !== null){
            permission_r = true;
        }else if(is_admin && permission === 2){
            permission_r = true;
        }

        return permission_r;
    }
    getRolesNames(){

    }
    findPermissions(arr, property_id) {
        //console.log('permisos', arr, property_id);
        let obj = arr.find(o => o.property === property_id);
        return  obj.sections;
    }

}
